module.exports = {
  comm: {
    home: 'Laman Utama',
    pricing: 'Harga',
    blog: 'Pengklonan Suara & API', // 更新
    language: 'Bahasa:',
    select_language: 'Pilih Bahasa',
    txt2voice: 'Teks ke Suara',
    voice2txt: 'Suara ke Teks',
    voiceclone: 'Klon Suara',
    video2txt: 'Video ke Teks',
    footer_help: 'Perlukan bantuan? Hantar e-mel kepada kami:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Log masuk',
    login_desc: 'Log masuk untuk mengakses akaun Penjana Suara TikTok anda',
    logout: 'Log keluar',
  },
  txt2voice: {
    title: 'Penjana Suara TikTok: Jana Suara AI TikTok yang Kelakar',
    description: 'Jana dan muat turun suara AI TikTok yang kelakar secara percuma, seperti suara Jessie, suara C3PO, suara Ghostface...',
    keywords: 'Penjana Suara TikTok, Suara AI TikTok, Penjana Suara Tik Tok',
    main_title_p01: 'Penjana Suara TikTok',
    main_title_p02: 'Jana Suara TikTok yang Kelakar: suara Jessie, suara C3PO, suara Ghostface',
    main_textarea_holder: 'Taip atau tampal teks di sini',
    main_genvoice: 'jana',
    main_generating: 'menjana',
    main_input_empty: 'sila taip atau tampal teks',
    daily_usage_limit_msg: '1) Had penggunaan harian maksimum telah dicapai. Sila datang semula esok. 2) Jika anda memerlukan lebih banyak penggunaan secara segera, sila hubungi kami melalui e-mel.',
    text_max_prompt1: 'Sila hadkan bilangan perkataan kepada',
    text_max_prompt2: 'perkataan atau kurang!',
    popup_nologin_title: 'Tingkatkan pelan langganan anda',
    popup_nologin_desc: 'Buka kunci lebih banyak masa penjanaan suara dengan menaik taraf ke langganan premium.',
    popup_nologin_btntext: 'Terokai pelan langganan',
    popup_nosub_title: 'Tingkatkan pelan langganan anda',
    popup_nosub_desc: 'Buka kunci lebih banyak masa penjanaan suara dengan menaik taraf ke langganan premium.',
    popup_nosub_btntext: 'Terokai pelan langganan',
    popup_sublimit_title: 'Langganan habis, sila naik taraf',
    popup_sublimit_desc: 'Tingkatkan pengalaman anda dengan ciri-ciri premium dan akses tanpa had kami.',
    popup_sublimit_btntext: 'Pergi beli lebih',
  },
  pricing: {
    new_features_alert: "📣 Kami telah melancarkan perkhidmatan 🎙️ Pengklonan Suara dan 🤖 API untuk ahli berbayar! (Selepas pembayaran, e-mel tiktokaivoicetool@gmail.com untuk bantuan dengan ciri-ciri ini). Selain itu, jangan ragu untuk menghantar e-mel kepada kami untuk sebarang permintaan penyesuaian😃", // 新增
    pricing_title: 'Dari pencipta individu hingga syarikat besar, ada pelan untuk anda.',
    pricing_desc: 'Lebih 100 suara semula jadi dan manusiawi. Lebih 15 bahasa. Harga terendah untuk hampir semua orang',
    sub_free_name: 'Percuma',
    sub_free_desc: 'Untuk individu yang ingin mencuba audio AI terkini',
    sub_free_content: [
      'Had 1000 aksara sebulan',
      '5 penjanaan sehari',
    ],
    sub_starter_name: 'Pemula',
    sub_starter_desc: 'Untuk pencipta yang menghasilkan kandungan premium untuk penonton global',
    sub_starter_content: [
      '1 klon suara',
      'Had 300,000 aksara sebulan (kira-kira 7 jam audio)',
      'Muat turun tanpa had',
      'Sokongan e-mel dalam masa 72 jam',
      'Akses keutamaan kepada suara dan ciri-ciri baharu',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Untuk pencipta yang mengembangkan pengeluaran kandungan mereka',
    sub_pro_content: [
      'Akses API',
      '3 klon suara',
      'Had 1,000,000 aksara sebulan (kira-kira 24 jam audio)',
      'Muat turun tanpa had',
      'Sokongan e-mel dalam masa 48 jam',
      'Akses keutamaan kepada suara dan ciri-ciri baharu',
    ],
    period_year: 'Tahunan',
    period_month: 'Bulanan',
    period_month_short: 'bln',
    billed_year: 'Dibilkan tahunan',
    most_popular: 'Paling popular',
    discount_quantity: 'Jimat $48',
    buy_btntext: 'Mulakan',
    pay_succ: 'Pembayaran berjaya!',
    pay_succ_desc: 'Terima kasih atas pembelian anda. Transaksi telah berjaya diproses.',
    pay_succ_btntext: 'Mula menggunakan TikTok Voice',
    pay_fail: 'Pembayaran gagal!',
    pay_fail_desc: "Maaf, kami tidak dapat memproses transaksi anda. Sila cuba lagi atau hubungi pasukan sokongan kami (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Kembali ke halaman utama',
  },
  setting: {
    setting: 'Tetapan',
    setting_title: 'Urus akaun, penggunaan dan langganan anda di sini.',
    basic_info: 'Maklumat asas', // 新增，放在 setting_title 下方
    user_name: 'Nama',
    user_email: 'E-mel',
    user_account: 'Akaun',
    user_subscript: 'Pelan semasa',
    user_usage: 'Penggunaan',
    manage_subscript: 'Urus langganan',
    use_limit_promote: "Anda telah menggunakan 5000 aksara daripada had 10000 aksara bulanan anda.",
    not_logged_in: 'Anda belum log masuk', // 新增
    go_to_login: 'Pergi ke log masuk >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "Penjana Suara TikTok",
    subtitle: "Log masuk untuk mengakses akaun Penjana Suara TikTok anda",
    alreadyLoggedIn: "Anda sudah log masuk",
    goToHome: "Pergi ke halaman utama"
  },
  faq: {
    quest1: 'Penjana Suara TikTok - Alat Suara AI TikTok Terbaik',
    answer1_1: 'Ia adalah alat penjana suara AI TikTok yang dibangunkan oleh teknologi tts TikTok terkini, ia boleh menjana suara TikTok seperti suara Jessie (suara perempuan), suara Siri, suara Ghostface, suara C3PO (suara robot), suara dalam (narator), suara Skye, suara hangat, suara Bestie, suara Hero (suara Chiris), suara empatik, suara serius, suara Joey, suara Stitch, suara Stormtrooper (Star Wars), suara Rocket (Guardians of the Galaxy).',
    answer1_2: 'Suara AI TikTok yang akan disokong tidak lama lagi termasuk: suara Derek, suara Trickster, suara Austin Butler, suara wartawan berita, suara Adam, suara anjing, suara Miley Cyrus, suara Alexa, suara alien, suara haiwan, suara bayi, suara komputer, suara chipmunk, suara echo, suara npr.',
    answer1_3: 'Selain itu, alat penjana suara TikTok menyokong beberapa bahasa lain, termasuk Bahasa Cina, Jepun, Korea, Vietnam, Thai, Hindi, Parsi, Rusia, Jerman, Perancis, Romania, Czech, Sepanyol, Portugis, Bengali, Itali, Arab, Urdu, Cina Tradisional dan Melayu.',
    answer1_4: 'Jika anda memerlukan suara tertentu dengan segera, sila hantarkan e-mel kepada saya.',

quest2: 'Apakah kelebihan Penjana Suara TikTok?',
    answer2_1: '- Alat penjana suara TikTok boleh menjana pelbagai jenis suara, yang sering digunakan dalam video TikTok.',
    answer2_2: '- Ia adalah penjana suara AI terkini yang boleh menjana suara tts TikTok yang mirip manusia.',
    answer2_3: '- Ia lebih mudah untuk penyuntingan video pada PC.',
    answer2_4: '- Anda boleh menggunakan beberapa suara yang tidak dapat dijumpai dalam aplikasi tts TikTok pada masa ini.',

quest3: 'Bagaimana menggunakan Penjana Suara TikTok?',
    answer3_1: 'Penjana suara TikTok sangat mudah digunakan:',
    answer3_2: '- Pilih bahasa dan aksen suara.',
    answer3_3: '- Taip teks yang ingin ditukar kepada suara dalam kotak input.',
    answer3_4: '- Tekan butang jana dan tunggu beberapa saat.',
    answer3_5: '- Main atau muat turun suara AI TikTok.',

quest4: 'Apakah suara TikTok yang paling terkenal dan kelakar?',
    answer4_1: 'Suara lelaki TikTok yang paling terkenal ialah suara dalam, yang secara rasmi disebut sebagai narator.',
    answer4_2: 'Untuk suara TikTok yang paling kelakar, saya secara peribadi mengesyorkan suara Ghostface dan suara C3PO. Suara-suara ini sering digunakan untuk penggandaan suara dalam video TikTok yang kelakar kerana mereka sangat mudah dikenali dan boleh menarik perhatian penonton dengan cepat.',

quest5: 'Apakah suara TikTok yang paling menjengkelkan?',
    answer5_1: 'Saya percaya tidak ada suara TikTok yang benar-benar "menjengkelkan".',
    answer5_2: 'Ia lebih bergantung kepada konteks dan kandungan di mana suara itu digunakan, yang boleh membuatkan beberapa orang merasa tidak selesa. Sebagai contoh, beberapa orang mungkin menganggap suara Jessie menjengkelkan kerana ia muncul terlalu kerap di TikTok pada suatu tempoh masa tertentu. Walau bagaimanapun, kerana nada dan kualitinya, ia sebenarnya adalah kesan suara TikTok yang hebat. Selain itu, pelakon suara TikTok di belakangnya sangat profesional dan dia juga menyiarkan videonya sendiri di TikTok.',
    answer5_3: 'Oleh itu, saya mengesyorkan agar semua orang meletakkan kebimbangan dan prasangka mereka ke tepi, mendengar suara dari alat ini beberapa kali dan dengan sabar mencari kesan suara TikTok yang paling sesuai untuk anda.',

quest6: 'Bagaimana menggunakan Penjana Suara TikTok untuk menjana suara Jessie?',
    answer6_1: '- Dalam menu lungsur pertama alat penjana suara TikTok, pilih English (US), kemudian dalam menu lungsur yang lain, pilih suara Jessie (suara perempuan).',
    answer6_2: '- Taip teks dalam kotak input dan klik butang jana.',
    answer6_3: '- Tunggu beberapa saat atau sehingga sepuluh saat, anda akan mendengar suara AI. Dalam bar alat, cari butang dengan anak panah ke bawah dan klik untuk memuat turunnya.',

quest7: 'Bagaimana menggunakan Penjana Suara TikTok untuk menjana suara C3PO?',
    answer7_1: '- Dalam menu lungsur pertama alat penjana suara TikTok, pilih English (US), kemudian dalam menu lungsur yang lain, pilih suara C3PO (suara robot).',
    answer7_2: '- Taip teks dalam kotak input dan klik butang jana.',
    answer7_3: '- Tunggu beberapa saat atau sehingga sepuluh saat, anda akan mendengar suara AI. Dalam bar alat, cari butang dengan anak panah ke bawah dan klik untuk memuat turunnya.',

quest8: 'Bagaimana menggunakan Penjana Suara TikTok untuk menjana suara Ghostface?',
    answer8_1: '- Dalam menu lungsur pertama alat penjana suara TikTok, pilih English (US), kemudian dalam menu lungsur yang lain, pilih suara Ghostface.',
    answer8_2: '- Taip teks dalam kotak input dan klik butang jana.',
    answer8_3: '- Tunggu beberapa saat atau sehingga sepuluh saat, anda akan mendengar suara AI. Dalam bar alat, cari butang dengan anak panah ke bawah dan klik untuk memuat turunnya.',

quest9: 'Bagaimana menggunakan Penjana Suara TikTok untuk menjana suara Siri?',
    answer9_1: '- Dalam menu lungsur pertama alat penjana suara TikTok, pilih English (US), kemudian dalam menu lungsur yang lain, pilih suara Siri.',
    answer9_2: '- Taip teks dalam kotak input dan klik butang jana.',
    answer9_3: '- Tunggu beberapa saat atau sehingga sepuluh saat, anda akan mendengar suara AI. Dalam bar alat, cari butang dengan anak panah ke bawah dan klik untuk memuat turunnya.',

quest10: 'Bagaimana menambah kesan suara TikTok pada video TikTok saya?',
    answer10_1: 'Jika anda ingin mengetahui cara menggunakan suara rasmi TikTok di TikTok, saya akan menulis posting blog khas mengenai topik ini tidak lama lagi.',
    answer10_2: 'Di sini, saya akan menjelaskan cara memuat naik suara anda ke TikTok setelah menjana dan memuat turunnya dari Penjana Suara TikTok.',
    answer10_3: '1. Jika anda telah menjana suara TikTok di PC anda dan ingin memuat naiknya ke TikTok atau aplikasi penyuntingan video lain di telefon anda, anda perlu memindahkan fail suara ke telefon anda. Untuk iPhone, anda boleh menggunakan airDrop untuk pemindahan. Untuk telefon Android, saya tidak begitu biasa dengan proses ini, tetapi anda pasti akan menemui cara dan alat untuk melakukannya.',
    answer10_4: '2. Setelah fail suara dipindahkan ke telefon anda, anda boleh membuka TikTok:',
    answer10_5: '- Ketik butang "+" di bahagian bawah antara muka, pilih video di telefon anda.',
    answer10_6: '- Setelah memuat naik video, cari ikon kotak di sebelah kanan skrin untuk masuk ke halaman penyuntingan.',
    answer10_7: '- Di bahagian bawah halaman, cari butang "tambah suara" dan ketik untuk memilih fail suara yang baru dipindahkan.',
    answer10_8: '- Dalam antara muka ini, anda boleh membuat beberapa penyesuaian pada video, kemudian ketik butang di sudut kanan atas untuk memuat naiknya.',

quest11: 'Adakah TikTok Voice percuma?',
    answer11: 'Ya, ia adalah alat percuma untuk menjana suara AI TikTok dari teks ke suara.',

quest12: 'Di mana saya boleh menjana suara TikTok popular dalam video TikTok saya?',
    answer12: 'Anda boleh melawat https://tiktokvoice.net/ untuk menambah suara AI TikTok pada video anda.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}